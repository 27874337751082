import React, { useContext, useState, useEffect } from "react";
import "./_Dashboard.scss";
import { useNavigate } from "react-router-dom";
import InfoContext from "../InfoContext";
//import JobRecommendationsContext from "../JobRecommendationsContext";
//import SkillsContext from "../SkillsContext";
import EventContext from "../EventContext";
//import SoftSkillsContext from "../SoftSkillsContext";
import Spinner from "react-bootstrap/Spinner";
import OpenJobsContext from "../OpenJobsContext";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import dashboardImg from "../../images/events_and_opportunities.jpg"; //taylor-grote-UiVe5QvOhao-unsplash-compressed2.jpg";
import dashboardImgCompressed from "../../images/events_and_opportunities2.jpg"; //taylor-grote-UiVe5QvOhao-unsplash-compressed2.jpg";
import ProgressiveImage from "../../components/ProgressiveImage";
import GlobalURLContext from "../GlobalURLContext";
import { shuffleArray } from "../../Helpers";

function Dashboard() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!eventContext.events?.length) {
            getEvents();
        }
    }, []);

    const globalURLContext = useContext(GlobalURLContext);
    const infoContext = useContext(InfoContext);

    function getEvents() {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 60000);
        let headers = new Headers();
        let token = window.sessionStorage.getItem("accessToken");
        headers.append("Authorization", `Bearer ${token}`);
        let options = {
            headers: headers,
            redirect: "follow",
            signal: controller.signal,
        };
        let url = `${globalURLContext.globalURL}/registeredevents`;

        fetch(url, options)
            .then((response) => {
                if (!response.ok)
                    throw new Error(`Unexpected response status ${response.status}`);
                else return response.json();
            })
            .then((result) => {
                let events = result;

                events = events?.map((e, idx) => ({ ...e, id: e.EVENT_ID }));
                events.sort((e1, e2) => {
                    if (new Date(e1.DATE) > new Date(e2.DATE)) return 1;
                    else if (new Date(e1.DATE) < new Date(e2.DATE)) return -1;
                    return 0;
                });
                eventContext.setEvents(events);
            })
            .catch((e) => {
                console.log(e);
                eventContext.setEvents([]);
            });
    }

    function filterEvents(events) {
        return events?.filter((e) => {
            if (!!e?.CONSTRAINTS) {
                for (let [k, v] of Object.entries(JSON.parse(e?.CONSTRAINTS))) {
                    console.log(k)
                    console.log(infoContext.info)
                    console.log(infoContext.info[k.toLowerCase()])
                    if (typeof v === "object" && !v?.includes(infoContext.info[k])) {
                        return false;
                    }
                }
            }
            return true;
        });
    }

    const navigate = useNavigate();
    //const infoContext = useContext(InfoContext);
    //const softSkillsContext = useContext(SoftSkillsContext);
    //const skillsContext = useContext(SkillsContext);

    //const jobRecommendationsContext = useContext(JobRecommendationsContext);

    const openJobsContext = useContext(OpenJobsContext);
    const eventContext = useContext(EventContext);
    const [eventsHovered, setEventsHovered] = useState(false);
    const [jobsHovered, setJobsHovered] = useState(false);

    return (
        <div className="dashboard-content">
            <div className="full-dashboard">
                <h3 className="welcome page-title">
                    Welcome to the New York Jobs CEO Council Talent Network Portal
                </h3>
                <div
                    style={{
                        margin:
                            "0px 24px 0px 24px",
                        borderRadius: "15px"
                    }}
                >
                    <ProgressiveImage
                        src={dashboardImg}
                        placeholderSrc={dashboardImgCompressed}
                        style={{
                            maxWidth: "100%",
                            minWidth: "100%",
                            borderRadius: "15px"
                        }}
                    />
                </div>
                <div className="dashb" >
                    <div
                        className="dashboard-half dashboard-left"
                        onMouseOver={() => setEventsHovered(true)}
                        onMouseLeave={() => setEventsHovered(false)}
                        onClick={() => navigate("/events")}
                        style={{ minHeight: "100%" }}
                    >
                        <div className="dashboard-band orange-band">
                            {" "}
                            <span style={{ margin: "auto" }}>UPCOMING EVENTS</span>
                        </div>
                        <div style={{ minHeight: "100%" }}>
                            {!!eventContext.events ? (
                                !filterEvents(eventContext.events)?.length ? (
                                    <div
                                        className={`dashboard-spinner-container`}
                                        style={{ display: "flex", alignItems: "center", flexDirection: "row", textAlign: "center", justifyContent: "center" }}
                                    >
                                        There are no upcoming events...
                                        <br />
                                        Please check back later
                                    </div>
                                ) : (
                                    filterEvents(eventContext.events)
                                        ?.slice(0, 3)
                                        ?.map((e, idx) => (
                                            <div id={`event-${idx}`} className="dashboard-band">
                                                <span
                                                    style={{
                                                        textOverflow: "ellipsis",
                                                        maxWidth: "75%",
                                                        whitespace: "nowrap",
                                                        fontSize: 16,
                                                        margin: "auto",
                                                        marginLeft: 0,
                                                    }}
                                                >
                                                    {e.TITLE}
                                                </span>
                                                <span
                                                    style={{
                                                        margin: "auto",
                                                        marginRight: 8,
                                                        justifyContent: "end",
                                                        display: "flex",
                                                        marginLeft: 12,
                                                        fontSize: 15,
                                                        minWidth: 120,
                                                    }}
                                                >
                                                    {new Date(e.DATE).toUTCString().slice(0, -13)}
                                                </span>
                                            </div>
                                        ))
                                )
                            ) : (
                                <div className={`dashboard-spinner-container`}>
                                    <Spinner
                                        className="dashboard-spinner"
                                        style={{ width: 70, height: 70 }}
                                        animation="border"
                                        variant="warning"
                                    />
                                </div>
                            )}
                        </div>
                        {filterEvents(eventContext.events)?.length > 0 && (
                            <div
                                style={{
                                    marginTop: 24,
                                    position: "absolute",
                                    bottom: 24,
                                    left: 12,
                                    textDecoration: eventsHovered && "underline",
                                }}
                            >
                                View all & register
                                <ArrowForwardIcon
                                    style={{
                                        marginLeft: 8,
                                        position: "relative",
                                        left: eventsHovered ? 2 : 0,
                                        transition: "left ease-out 0.2s",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div
                        className="dashboard-half dashboard-right"
                        onMouseOver={() => setJobsHovered(true)}
                        onMouseLeave={() => setJobsHovered(false)}
                        onClick={() => navigate("/jobs")}
                    >
                        <div className="dashboard-band orange-band">
                            <span style={{ margin: "auto" }}>
                                OPEN POSITIONS{/*JOB RECOMMENDATIONS*/}
                            </span>
                        </div>
                        <div style={{ minHeight: 250 }}>
                            {!!openJobsContext.openJobs ? (
                                !openJobsContext.openJobs?.length ? (
                                    <div
                                        className={`dashboard-spinner-container`}
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        There are currently no open jobs...
                                        <br />
                                        Please check back later
                                    </div>
                                ) : (
                                    openJobsContext.openJobs?.slice(0, 3)?.map((j, idx) => (
                                        <div id={`job-${idx}`} className="dashboard-band">
                                            <span
                                                style={{
                                                    textOverflow: "ellipsis",
                                                    maxWidth: "70%",
                                                    whitespace: "nowrap",
                                                    fontSize: 15,
                                                    margin: "auto",
                                                    marginLeft: 0,
                                                }}
                                            >
                                                {j.POSITION}
                                            </span>
                                            <span
                                                style={{
                                                    margin: "auto",
                                                    fontSize: 15,
                                                    justifyContent: "end",
                                                    display: "flex",
                                                    marginRight: 8,
                                                    marginLeft: 12,
                                                    minWidth: 100,
                                                    textAlign: "end"
                                                }}
                                            >
                                                {j.COMPANY}
                                            </span>
                                        </div>
                                    ))
                                )
                            ) : (
                                /*jobRecommendationsContext.recos?.length > 0 ? jobRecommendationsContext.recos?.map(j => <div className="dashboard-band"><span style={{textOverflow:"ellipsis", maxWidth:"100%",whitespace:"nowrap",margin:"auto",marginLeft:0}}>{j.ROLE}</span><span style={{margin:"auto",marginRight:0}}>{j.COMPANY}</span></div>)*/
                                <div className={`dashboard-spinner-container`}>
                                    <Spinner
                                        className="dashboard-spinner"
                                        style={{ width: 70, height: 70 }}
                                        animation="border"
                                        variant="warning"
                                    />
                                </div>
                            )}
                        </div>
                        {openJobsContext.openJobs?.length > 0 && (
                            <div
                                style={{
                                    marginTop: 24,
                                    position: "absolute",
                                    bottom: 24,
                                    left: 12,
                                    textDecoration: jobsHovered && "underline",
                                }}
                            >
                                Browse all open jobs
                                <ArrowForwardIcon
                                    style={{
                                        marginLeft: 8,
                                        position: "relative",
                                        left: jobsHovered ? 2 : 0,
                                        transition: "left ease-out 0.2s",
                                    }}
                                />
                            </div>
                        )}

                        {/*<div>You have {jobRecommendationsContext.recos?.length || 0} new job recommendations</div>*/}
                        {/*<div className="home-tile-nav">Browse jobs <ArrowRight /></div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;