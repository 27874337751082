import React, { useState, useContext, useRef, useEffect } from "react";
import LoggedOff from "./pages/LoggedOff";
import LoggedOn from "./pages/LoggedOn";
import Resources from "./pages/Resources";
import Jobs from "./pages/Jobs";
import Events from "./pages/Events";
//import Planning from "./pages/Planning";
//import Skills from "./pages/Skills";
import { Routes, Route } from "react-router-dom";
import InfoContext from "./components/InfoContext";
import EventContext from "./components/EventContext";
import VisitedPagesContext from "./components/VisitedPagesContext";
import IsChatLaunchedContext from "./components/IsChatLaunchedContext";
//import SkillsContext from "./components/SkillsContext";
//import SoftSkillsContext from "./components/SoftSkillsContext";
import OpenJobsContext from "./components/OpenJobsContext";
import GlobalURLContext from "./components/GlobalURLContext";
import Profile from "./pages/Profile";
import ProfileCheckin from "./pages/ProfileCheckin";
import Account from "./pages/Account";
//import JobRecommendationsContext from "./components/JobRecommendationsContext";
import Header from "./components/Header";

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  //const [loggedIn, setLoggedIn] = useState(true);
  const [token, setToken] = useState("");

  const [info, setInfo] = useState({ updated: false });
  const [events, setEvents] = useState(null);
  const [assistantLaunched, setAssistantLaunched] = useState(false);
  //const [skills, setSkills] = useState([]);
  //const [softSkills, setSoftSkills] = useState([]);
  const [visited, setVisited] = useState({ events: false, jobs: false });

  //const [recos, setRecos] = useState([]);
  const [openJobs, setOpenJobs] = useState(null);

  //const [globalURL, setGlobalURL] = useState("http://vanadate1.fyre.ibm.com:3003");
  const [globalURL, setGlobalURL] = useState("https://student-site-backend.xtifk3z8tjy.us-east.codeengine.appdomain.cloud");
  const [showUpperMenuProp, setShowUpperMenuProp] = useState(false);
  const upperMenuRef = useRef(null);





  useEffect(() => {
    window.onscroll = () => setShowUpperMenuProp(false);
    const handleClickOutside = (event) => {
      if (
        upperMenuRef.current &&
        !upperMenuRef.current.contains(event.target)
      ) {
        setShowUpperMenuProp(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [loggedIn]);


  return (
    <>
      <GlobalURLContext.Provider value={{ globalURL, setGlobalURL }}>
        <IsChatLaunchedContext.Provider
          value={{ assistantLaunched, setAssistantLaunched }}
        >
          <InfoContext.Provider value={{ info, setInfo }}>
            <EventContext.Provider value={{ events, setEvents }}>
              <VisitedPagesContext.Provider value={{ visited, setVisited }}>
                <OpenJobsContext.Provider value={{ openJobs, setOpenJobs }}>
                  <Header
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    ref={upperMenuRef}
                    showUpperMenuProp={showUpperMenuProp}
                    setShowUpperMenuProp={setShowUpperMenuProp}
                  />
                  {!loggedIn ? (
                    <LoggedOff
                      setLoggedIn={setLoggedIn}
                      token={token}
                      setToken={setToken}
                    />
                  ) : (
                    <Routes>
                      <Route
                        path="/home"
                        element={
                          <LoggedOn
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                            token={token}
                            setToken={setToken}
                          />
                        }
                      />
                      <Route
                        path="/checkin"
                        element={
                          <ProfileCheckin
                            token={token}
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                          />
                        }
                      />
                      <Route
                        path="/profile"
                        element={
                          <Profile
                            token={token}
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                          />
                        }
                      />
                      <Route
                        path="/resources"
                        element={
                          <Resources
                            token={token}
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                          />
                        }
                      />
                      <Route
                        path="/events"
                        element={
                          <Events
                            token={token}
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                          />
                        }
                      />
                      <Route
                        path="/jobs"
                        element={
                          <Jobs
                            token={token}
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                          />
                        }
                      />
                      {/*<Route
                        path="/plan"
                        element={
                          <Planning
                            token={token}
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                          />
                        }
                      />*/}
                      <Route
                        path="/account"
                        element={
                          <Account
                            token={token}
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                          />
                        }
                      />
                    </Routes>
                  )}
                </OpenJobsContext.Provider>
              </VisitedPagesContext.Provider>
            </EventContext.Provider>
          </InfoContext.Provider>
        </IsChatLaunchedContext.Provider>
      </GlobalURLContext.Provider>
    </>
  );
};

export default App;
