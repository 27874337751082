import React, { useState, useContext, useEffect } from "react";
import "../../index.scss";
import "./_LoggedOff.scss";
import MainImageCompressed from "../../images/jobscouncil_compressed2.jpg";
import MainImage from "../../images/jobscouncil.jpg";
import ProgressiveImage from "../../components/ProgressiveImage";
import AppID from "ibmcloud-appid-js";
import { useNavigate } from "react-router-dom";
import GlobalURLContext from "../../components/GlobalURLContext";
import Signup from "../../components/Signup";

const LoggedOff = ({ loggedIn, setLoggedIn }) => {

  let appId = new AppID();
  const navigate = useNavigate();
  const [showVerificationMessage, setShowVerificationMessage] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const globalURLContext = useContext(GlobalURLContext);
  useEffect(() => {
    if (window.location.pathname === "/verified")
      setShowVerificationMessage(true);
  }, []);

  const login = async () => {
    try {
      const token = await appId.signin();
      sessionStorage.setItem(
        "userName",
        token.idTokenPayload.given_name + " " + token.idTokenPayload.family_name
      );
      sessionStorage.setItem("identityToken", token.idToken);
      sessionStorage.setItem("accessToken", token.accessToken);
      sessionStorage.setItem("globalURL", globalURLContext.globalURL);
      navigate("/checkin");
      setLoggedIn(true);
    } catch (e) {
      console.log(e.message);
    }
  };

  let initAppID = async () => {
    try {
      await appId.init({
        clientId: "65e3584c-d47e-4de3-8400-520166ae9465",
        discoveryEndpoint:
          "https://us-south.appid.cloud.ibm.com/oauth/v4/bde6b9c1-2ba3-476c-82cd-e4199817ecef/.well-known/openid-configuration",
        popup: { height: 620, width: 550 },
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(initAppID, [showSignup, showVerificationMessage]);
  /*
  function postLogin() {
    let token = window.sessionStorage.getItem("accessToken");

    let headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
  
    let options = {
        method: "POST",
        redirect: "follow",
        headers: headers,
    }
    let url = new URL(`${globalURLContext.globalURL}/login`);
    fetch(url, options)
    .then(response => response.json())
    .catch(e => console.log(e));
  }*/
  /*
  function getSkills() {
    let token = window.sessionStorage.getItem("accessToken")
    let url = `${globalURLContext.globalURL}/skills`;

    let headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    let options = {
        headers: headers,
        redirect: "follow"
    }

    fetch(url, options)
    .then(response => {
        if (!response.ok) throw new Error(`Unexpected response status ${response.status}`)
        return response.json()
    })
    .then(result => {
        let userSkills = result?.SKILLS;
        let userSoftSkills = result?.SOFT_SKILLS;

        skillsContext.setSkills(JSON.parse(userSkills));
        softSkillsContext.setSoftSkills(JSON.parse(userSoftSkills))
    })
    .catch(e => console.log(e))   
}
*/
  /*
  function getOpenJobs() {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 60000);
    let token = window.sessionStorage.getItem("accessToken")
  
    let headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
  
    let options = {
        method: "GET",
        redirect: "follow",
        headers: headers,
        signal: controller.signal
    }
    let url = new URL(`${globalURLContext.globalURL}/openjobs`);
    fetch(url, options)
    .then(response => response.json())
    .then(result => {
        openJobsContext.setOpenJobs(result);
    })
    .catch(e => {
      console.log(e);
      openJobsContext.setOpenJobs([]);
    });
  }
  
  function getJobRecommendations() {
    let token = window.sessionStorage.getItem("accessToken")
  
    let headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
  
    let options = {
        method: "GET",
        redirect: "follow",
        headers: headers
    }
  
    fetch(`${globalURLContext.globalURL}/recommendations`, options)
    .then(response => response.json())
    .then(result => {
        jobRecommendationsContext.setRecos(result);
    })
    .catch(e => {
      console.log(e);
      //resumeContext.setResumeUploaded(false);
      jobRecommendationsContext.setRecos([]);
    });
  }
  
  function getInfo() {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 60000);
    let headers = new Headers();
    let token = window.sessionStorage.getItem("accessToken")
    headers.append("Authorization", `Bearer ${token}`)
    let options = {
        headers: headers,
        redirect: "follow",
        signal: controller.signal
    }
    let url = new URL(`${globalURLContext.globalURL}/userinfo`);
  
    fetch(url, options
    ).then( response => {
        if (!response.ok) throw new Error(`Unexpected response status ${response.status}`)
        else return response.json();
    }).then(({ FIRST_NAME, LAST_NAME, EMAIL1, LAST_LOGIN }) => {
        infoContext.setInfo(info => ({...info, firstName: FIRST_NAME, lastName: LAST_NAME, email: EMAIL1, lastLogin: LAST_LOGIN}));
    }).catch(e => console.log(e));
  }
  
  
  
  function getEvents() {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 60000);
    let headers = new Headers();
    let token = window.sessionStorage.getItem("accessToken")
    headers.append("Authorization", `Bearer ${token}`)
    let options = {
        headers: headers,
        redirect: "follow",
        signal: controller.signal
    }
    let url = `${globalURLContext.globalURL}/registeredevents`;
  
    fetch(url, options
    ).then( response => {
        if (!response.ok) throw new Error(`Unexpected response status ${response.status}`)
        else return response.json()
    }).then(result => {
        let events = result;
        //events = events.map((e, idx) => ({...e, id: idx, REGISTRATION: registeredEvents?.some(ev => ev.EVENT_ID == e.EVENT_ID) ? <div style={{display:"flex"}}><span style={{marginTop:"15px", marginRight:4}}> Registered</span><OverflowMenu size="lg" flipped><OverflowMenuItem itemText={"Cancel"}></OverflowMenuItem></OverflowMenu></div> : <IconButton id={e.EVENT_ID} onClick={e => register(e)} sx={{marginTop:"9px", marginLeft:"12px"}}><Add /></IconButton>}))
        //events = events.map((e, idx) => ({...e, id: idx.toString(), REGISTRATION: e.REGISTRATION_STATUS == "Registered" ? <div style={{display:"flex"}}><span style={{marginTop:"15px", marginRight:4}}>Registered</span><OverflowMenu size="lg" flipped><OverflowMenuItem id={e.EVENT_ID} itemText={"Cancel"} onClick={unregister}></OverflowMenuItem></OverflowMenu></div> : <IconButton id={e.EVENT_ID} onClick={e => register(e)} sx={{marginTop:"9px", fontSize:"14px", fontFamily:"Trim-Regular"}}>Register <Add /></IconButton>}))
        events = events?.map((e, idx) => ({...e, id: e.EVENT_ID}))//idx.toString(), REGISTRATION: e.REGISTRATION_STATUS == "Registered" ? <div style={{display:"flex", paddingLeft:12}}><span style={{marginTop:"15px", marginRight:4, fontWeight:800}}>Registered</span><OverflowMenu size="lg" flipped><OverflowMenuItem id={e.EVENT_ID} itemText={"Cancel"} onClick={unregister}></OverflowMenuItem></OverflowMenu></div> : <Button id={e.EVENT_ID} renderIcon={Add} onClick={register} className="register-btn" >Register</Button>}))
        eventContext.setEvents(events);
    }).catch(e => {
      console.log(e);
      eventContext.setEvents([]);
    })
  }*/



  return (
    <div className="page white-page">
      <div className="login-page">
        <div className="image-container">
          <ProgressiveImage
            src={MainImage}
            placeholderSrc={MainImageCompressed}
            style={{
              width: "100%",
              margin: "auto",
              minWidth: "min(100%,500px)",
              paddingLeft: 24,
              paddingRight: 24,
            }}
          />
        </div>

        <div className="value-prop" style={{}}>
          {!showVerificationMessage &&
            <h1 className="title">
              Welcome to the New York Jobs CEO Council Talent Network Portal
            </h1>}
          {!showSignup ? (
            <>
              {showVerificationMessage ? (
                <>
                  <h1 className="title">Thank you for verifying your email!</h1>
                  <p className="description">
                    You are now all set to log into the New York Jobs CEO
                    Council <br />Talent Network Portal!
                  </p>
                </>
              ) : (
                <>

                  <p className="description">
                    The New York Jobs CEO Council mission is to strengthen New
                    York by bringing together business, education and community
                    to equip New Yorkers for the jobs of tomorrow.
                  </p>
                  <p className="description">
                    This portal is currently available to the Jobs Council
                    Talent Network.
                  </p>
                </>
              )}

              {showVerificationMessage ? (
                <button
                  className="signup-btn"
                  onClick={() => { login() }}
                  kind="primary"
                >
                  <span style={{ marginRight: 0, fontSize: 18 }}>
                    Log In
                  </span>
                </button>
              ) : (
                <>
                  <button
                    className="signup-btn"
                    onClick={() => setShowSignup(true)}
                    kind="primary"
                  >
                    <span style={{ marginRight: 0, fontSize: 18 }}>
                      Sign Up
                    </span>
                  </button>
                  <button
                    className="login-btn"
                    onClick={login}
                    kind="primary"
                  >
                    <span style={{ marginRight: 0, fontSize: 18 }}>
                      Log In
                    </span>
                  </button>
                </>
              )}
            </>
          ) : (
            <Signup setShowSignup={setShowSignup}></Signup>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoggedOff;
