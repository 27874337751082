import {
    Button,
    TextInput,
    Form,
    Stack,
    PasswordInput,
    Tab,
    Tabs,
    TabList,
    Switch,
    ContentSwitcher,
    Loading,
} from "@carbon/react";
import React, { useState, useEffect, useContext } from "react";
import { ArrowRight, Add } from "@carbon/icons-react";
import { useParams, useSearchParams } from "react-router-dom";
import "./_Signup.scss";
import GlobalURLContext from "../GlobalURLContext";
import { ArrowForward, ArrowLeft } from "@mui/icons-material";
import { validateInput } from "../../Helpers";
import Spinner from "react-bootstrap/Spinner";

function Signup({ setShowSignup }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signUpEmail, setSignUpEmail] = useState("");
    const [signUpPassword, setSignUpPassword] = useState("");
    const [signUpPassword2, setSignUpPassword2] = useState("");
    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [emailExists, setEmailExists] = useState(false);
    const [signedUp, setSignedUp] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidFName, setInvalidFName] = useState(false);
    const [invalidLName, setInvalidLName] = useState(false);
    const [loginOrSignUp, setLoginOrSignUp] = useState("signup");
    const globalURLContext = useContext(GlobalURLContext);
    const [isLoading, setIsLoading] = useState(false);

    //let searchParams = useSearchParams();
    useEffect(() => {
        const params_ = new URLSearchParams(window.location.search);
        for (let p of params_.entries()) {
            if (p[0] == "signup") {
                setLoginOrSignUp("signup");
                break;
            }
        }
    }, []);

    function handleSignUp() {


        let flag = 0;
        if (!fName || !validateInput(fName, "name")) {
            setInvalidFName(true);
            flag = 1;
        }
        if (!lName || !validateInput(lName, "name")) {
            setInvalidLName(true);
            flag = 1;
        }
        if (!validateInput(signUpEmail, "email")) {
            setInvalidEmail(true);
            flag = 1;
        }
        if (!validatePassword(signUpPassword)) {
            setInvalidPassword(true);
            flag = 1;
        }
        if (signUpPassword !== signUpPassword2) {
            setInvalidPassword(true);
            flag = 1;
        }
        if (flag) return
        setIsLoading(true);
        console.log(new URLSearchParams(window.location.search).get("origin"));
        sessionStorage.setItem(
            "origin",
            new URLSearchParams(window.location.search).get("origin") || ""
        )
        let body = {
            firstName: fName,
            lastName: lName,
            email: signUpEmail,
            password: signUpPassword,
            origin: new URLSearchParams(window.location.search).get("origin")?.toString() || ""
        };

        let headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        let options = {
            method: "POST",
            redirect: "follow",
            headers: headers,
            body: JSON.stringify(body),
        };

        let url = new URL(`${globalURLContext.globalURL}/signup`);
        fetch(url, options)
            .then((response) => {
                if (response.status === 409)
                    throw new Error("Email already exists");
                response.json()
            })
            .then(() => {
                setSignedUp(true)
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                // TODO only set invalid email when email already exists
                setInvalidEmail(true);
                setEmailExists(true);
                setIsLoading(false);
            });
    }

    function handleLogin() { }

    function handleContinue() {
        if (loginOrSignUp === "login") {
            handleLogin();
        } else if (loginOrSignUp === "signup") {
            handleSignUp();
        }
    }

    function handleCancel() {
        setShowSignup(false);
    }

    function validatePassword(password) {
        let pass = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]))(?=(.*)).{12,}$/.test(password)
        ///^.{12,}$/.test(password) && /^.*[^a-zA-Z0-9].*$/.test(password) && /^.*\d.*$/.test(password) && /^.*[a-z].*$/.test(password) && /^.*[A-Z].*$/.test(password)
        return pass;
    }

    return (
        <div className="signup-form">
            <div
                style={{
                    maxWidth: 600,
                    width: "90%",
                    minWidth: 400,
                    marginTop: 4,
                    height: 320,
                }}
            >
                <Stack gap={4}>
                    {/*} <ContentSwitcher onChange={console.log}>
                    <Switch onClick={() => setLoginOrSignUp("login")} className="login-switch" name={'Log in'} text='Log in ' />
                    <Switch onClick={() => setLoginOrSignUp("signup")} name={'Sign up'} text='Sign up' />
    </ContentSwitcher>*/}

                    {/*<TabList style={{ width: "100%" }}>
                    <Tab disabled={false} onClick={() => setLoginOrSignUp("login")} className="login-switch" name={'Log in'} text='Log in '>Log in</Tab>
                    <Tab disabled={false} onClick={() => setLoginOrSignUp("signup")} name={'Sign up'} text='Sign up'>Sign up</Tab>

</TabList>*/}
                    {signedUp ? (
                        <>
                            <p style={{ lineHeight: "28px", marginBottom: 10, marginTop: 0 }}>
                                Thank you for signing up! <br />
                                You should receive a confirmation email shortly.
                                <br />
                                Please verify your email before logging in.
                            </p>
                            <a style={{ color: "rgb(247,135,15)", fontFamily: "Trim-SemiBold", cursor: "pointer" }} onClick={() => { setShowSignup(false); setSignedUp(false) }}><ArrowLeft />Take me back to the main page</a>
                        </>
                    ) : (
                        <>
                            {loginOrSignUp === "login" ? (
                                <>
                                    <TextInput large size="lg" labelText="Email"></TextInput>
                                    <PasswordInput
                                        style={{ height: 48 }}
                                        large
                                        size="lg"
                                        labelText="Password"
                                    ></PasswordInput>
                                </>
                            ) : (
                                <>
                                    <div style={{ display: "flex", gap: 8 }}>
                                        <TextInput
                                            large
                                            size="md"
                                            onChange={(e) => { setFName(e.target.value); setInvalidFName(false) }}
                                            labelText="First Name*"
                                            invalid={invalidFName}
                                        ></TextInput>
                                        <TextInput
                                            large
                                            size="md"
                                            onChange={(e) => { setLName(e.target.value); setInvalidLName(false) }}
                                            labelText="Last Name*"
                                            invalid={invalidLName}
                                        ></TextInput>
                                    </div>
                                    <TextInput
                                        large
                                        size="md"
                                        invalid={invalidEmail}
                                        invalidText={emailExists ? "An account is already associated with this email." : "Please enter a valid email"}
                                        //errorLabel="Error: an account associated with this email already exists"
                                        onChange={(e) => { setSignUpEmail(e.target.value); setInvalidEmail(false); setEmailExists(false) }}
                                        labelText="Email*"
                                    ></TextInput>
                                    <div style={{ display: "flex", gap: 8 }}>
                                        <PasswordInput
                                            invalidText="The password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character, and must contain at least 12 characters"
                                            invalid={invalidPassword}
                                            onChange={(e) => {
                                                setSignUpPassword(e.target.value);
                                                setInvalidPassword(false);
                                            }}
                                            large
                                            size="md"
                                            labelText="Password*"
                                        ></PasswordInput>
                                        <PasswordInput
                                            invalidText="Passwords must match"
                                            invalid={invalidPassword}
                                            onChange={(e) => {
                                                setSignUpPassword2(e.target.value);
                                                setInvalidPassword(false);
                                            }}
                                            large
                                            size="md"
                                            labelText="Confirm Password*"
                                        ></PasswordInput>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: 4,
                                            justifyContent: "space-evenly",
                                            marginTop: 8
                                        }}
                                    >
                                        <button
                                            className="signup-btn"
                                            //renderIcon={ArrowForwardIcon}
                                            onClick={handleCancel}
                                            kind="primary"
                                            style={{ flex: 1, fontSize: 16, lineHeight: "18px", textAlign: "center" }}
                                        >
                                            {/*Cancel*/}<div>Have an account?</div><div> Log in</div>
                                        </button>

                                        <button
                                            className="login-btn"
                                            //renderIcon={ArrowForwardIcon}
                                            onClick={handleContinue}
                                            kind="primary"
                                            style={{
                                                flex: 1,
                                                justifyContent: "center",
                                                fontSize: 18,
                                                textAlign: "center",
                                                alignContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            {isLoading ? <Spinner
                                                //className="dashboard-spinner"
                                                style={{ width: 30, height: 30, marginTop: 3 }}
                                                animation="border"
                                            //variant="warning"
                                            /> :
                                                "Continue"}
                                            {/*<span style={{ marginRight: 17 }}>
                                                {/*Sign up*}Sign Up
                                            </span>
                                            <ArrowForward
                                                style={{ position: "relative", top: "0px" }}
                            /></div>*/}
                                        </button>
                                    </div>{" "}
                                </>
                            )}
                        </>
                    )}
                </Stack>
            </div>
        </div>
    );
}

export default Signup;
