import React, { useEffect } from "react";
import ProfileComponent from "../../components/ProfileComponent/ProfileComponent";
import Footer from "../../components/Footer";


function ProfileCheckin(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="page white-page">
            <ProfileComponent {...props} isCheckIn={true}/>
            <Footer/>
        </div>
    )
}



export default ProfileCheckin;