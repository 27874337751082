import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
} from "react";
//import AppID from "ibmcloud-appid-js";
//import LogoImage from "../../images/nyj_logo.png";
import Logo from "../../images/logo-new.png";
import { useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";
import "./_Header.scss";
import {
  OverflowMenu,
  OverflowMenuItem,
  HeaderName,
  HeaderMenuButton,
  HeaderGlobalBar,
  Header as HeaderCarbon,
  HeaderGlobalAction,
  SideNav,
  SideNavItem,
  SideNavItems,
  SideNavMenu,
  SideNavMenuItem,
  SwitcherDivider,
  HeaderPanel,
  SideNavLink,
  SideNavDivider,
  Theme,
  Button,
} from "@carbon/react";
import {
  UserAvatarFilled,
  UserAvatar,
  CaretUp,
  ChevronUp,
} from "@carbon/icons-react";
import { IconButton } from "@mui/material";
import {
  Work,
  Home,
  AccountCircle,
  Event,
  GroupWork,
  ShowChart,
  Settings,
  Clear,
  Add,
  Person,
  Logout,
  AccountCircleRounded,
  AccountCircleSharp,
  Menu,
  AccountCircleOutlined,
} from "@mui/icons-material";
import InfoContext from "../InfoContext";
import VisitedPagesContext from "../VisitedPagesContext";
import EventContext from "../EventContext";

const Header = forwardRef(function Header(
  { loggedIn, setLoggedIn, showUpperMenuProp, setShowUpperMenuProp },
  ref
) {
  //function Header({ loggedIn, setLoggedIn, showUpperMenuProp, ref }) {

  const navigate = useNavigate();

  const [showUpperMenu, setShowUpperMenu] = useState(false);
  const infoContext = useContext(InfoContext);
  const eventContext = useContext(EventContext);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    setShowUpperMenu(showUpperMenuProp);
  }, [showUpperMenuProp]);

  useEffect(() => {
    setShowUpperMenuProp(showUpperMenu);
  }, [showUpperMenu]);

  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  let logout = async () => {
    try {
      sessionStorage.clear();
      window.location.reload();
      //onpagehide
      window.onunload = function () {
        setLoggedIn(false);
      };
    } catch (e) {
      console.log(e.message);
    }
  };

  const visitedPagesContext = useContext(VisitedPagesContext);

  function filterEvents(events) {
    return events?.filter((e) => {
      if (!!e?.CONSTRAINTS) {
        for (let [k, v] of Object.entries(JSON.parse(e?.CONSTRAINTS))) {
          if (typeof v === "object" && !v?.includes(infoContext.info[k])) {
            return false;
          }
        }
      }
      return true;
    });
  }

  return (
    <>
      <div
        className={`header ${loggedIn && window.location.pathname !== "/checkin"
          ? ""
          : "logged-out-checkin"
          }`}
      >
        <div style={{ display: "flex" }}>
          <div
            className={`header-logo-container ${window.location.pathname !== "/checkin" &&
              "header-logo-container-clickable"
              }`}
            onClick={() =>
              window.location.pathname !== "/checkin" && navigate("/home")
            }
          >
            <img
              src={Logo}
              className="header-logo"
              alt="New York Jobs CEO Council Logo"
            />
          </div>
          {loggedIn && window.location.pathname !== "/checkin" && (
            <div className="navigation">
              <div
                className="nav-item"
                onClick={() => {
                  navigate("/events");
                  visitedPagesContext.setVisited({
                    ...visitedPagesContext.visited,
                    events: true,
                  });
                }}
              >
                <Badge
                  badgeContent={
                    visitedPagesContext.visited.events
                      ? null
                      : filterEvents(eventContext.events)?.filter(
                        (e) =>
                          new Date(e.CREATED || null) >
                          new Date(infoContext.info?.lastLogin || null)
                      )
                        ?.length /*REGISTRATION_STATUS==="Registered")?.length*/
                  }
                  color="error"
                >
                  Events
                </Badge>
              </div>
              <div className="nav-item" onClick={() => navigate("/resources")}>
                Resources
              </div>
              <div className="nav-item" onClick={() => navigate("/jobs")}>
                Jobs
              </div>
              {/*<div className="nav-item" onClick={() => navigate("/plan")}>
                Plan
              </div>*/}
            </div>
          )}
        </div>
        {loggedIn && (
          <div ref={ref}>
            <IconButton
              id="upper-btn"
              className="upper-btn"
              sx={{ marginRight: "16px" }}
              onClick={(e) => {
                setShowUpperMenu(
                  (showUpperMenu) => !showUpperMenu
                ); /*e.stopPropagation()*/
              }}
            >
              <Menu sx={{ height: "40px", width: "40px", color: "#f7870f" }} />
            </IconButton>
            {showUpperMenu && (
              <div>
                <HeaderPanel
                  aria-label="Environment info"
                  className={`upper-menu ${window.location.pathname === "/checkin" &&
                    "upper-menu-checkin"
                    }`}
                  style={{
                    backgroundColor: "white",
                    border: "1.5px solid rgb(247,135,15)",
                  }}
                  expanded={true}
                >
                  <SideNavItems>
                    {window.location.pathname !== "/checkin" &&
                      window.location.pathname !== "/" && (
                        <>
                          {!!infoContext.info && (
                            <SideNavItem className="profile-name">
                              <span className="initials">
                                {infoContext.info?.firstName?.slice(0, 1)}
                                {infoContext.info?.lastName?.slice(0, 1)}
                              </span>
                              <span
                                style={{ maxWidth: 110, overflow: "hidden", }}
                              >
                                {infoContext.info?.firstName &&
                                  infoContext.info?.lastName
                                  ? infoContext.info?.firstName?.length < 9 &&
                                    infoContext.info?.lastName?.length < 9
                                    ? infoContext.info?.firstName +
                                    " " +
                                    infoContext.info?.lastName
                                    : infoContext.info?.firstName +
                                    " " +
                                    infoContext.info?.lastName[0] +
                                    "."
                                  : window.sessionStorage.getItem("userName")}
                              </span>
                            </SideNavItem>
                          )}
                          <SideNavDivider />
                          <SideNavLink
                            className="sidenav-item"
                            onClick={() => {
                              navigate("/profile");
                              setShowUpperMenu(false);
                            }}
                          >
                            <AccountCircle sx={{ color: "rgb(91,89,89)" }} />
                            <span
                              className="upper-right-menu-item"
                              style={{ paddingLeft: 12 }}
                            >
                              My Profile
                            </span>
                          </SideNavLink>
                          <SideNavLink
                            className="sidenav-item"
                            onClick={() => {
                              navigate("/account");
                              setShowUpperMenu(false);
                            }}
                          >
                            <Settings sx={{ color: "rgb(91,89,89)" }} />
                            <span
                              className="upper-right-menu-item"
                              style={{ paddingLeft: 12 }}
                            >
                              My Account
                            </span>
                          </SideNavLink>
                          <SideNavDivider />
                        </>
                      )}
                    <SideNavLink className="sidenav-item" onClick={logout}>
                      <Logout sx={{ color: "rgb(91,89,89)" }} />
                      <span
                        className="upper-right-menu-item"
                        style={{ paddingLeft: 12 }}
                      >
                        Log out
                      </span>
                    </SideNavLink>
                  </SideNavItems>
                </HeaderPanel>
              </div>
            )}
          </div>
        )}
      </div>
      {loggedIn &&
        window.location.pathname !== "/checkin" &&
        window.location.pathname !== "/" &&
        window.innerWidth < 680 && (
          <div className="narrow-menu">
            <div
              className="nav-item"
              onClick={() => {
                navigate("/events");
                visitedPagesContext.setVisited({
                  ...visitedPagesContext.visited,
                  events: true,
                });
              }}
            >
              <Badge
                badgeContent={
                  visitedPagesContext.visited.events
                    ? null
                    : eventContext.events?.filter(
                      (e) =>
                        new Date(e.CREATED || null) >
                        new Date(infoContext.info?.lastLogin || null)
                    )?.length /*REGISTRATION_STATUS==="Registered")?.length*/
                }
                color="error"
              >
                Events
              </Badge>
            </div>
            <div className="nav-item" onClick={() => navigate("/resources")}>
              Resources
            </div>
            <div className="nav-item" onClick={() => navigate("/jobs")}>
              Jobs
            </div>
            {/*<div className="nav-item" onClick={() => navigate("/plan")}>
              Plan
            </div>*/}
          </div>
        )}
    </>
  );
});

export default Header;
