import React, { useContext } from "react";

export function validateInput(input, type) {
  try {
    if (typeof input !== 'string' && input !== null && typeof input !== 'undefined') {
      input = input.toString();
    }
    let regex = null;
    switch (type) {
      case "race":
      case "college":
      case "degree":
      case "credits":
      case "major":
      case "gender":
      case "class":
      case "cuny":
      case "employment_status":
      case "employment_type":
      case "source":
      case "employer":
        regex = /^$|^[a-zA-Z\/ -:;\(\)\.\&,']{2,64}$/;
        return typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "student_status":
        regex = /^[A-Za-z -]{0,32}$/;
        return typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "company":
        regex = /^$|^[a-zA-Z\/ -:\(\)\.\&,']{2,64}$/;
        return typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "gpa":
        regex = /^[a-zA-Z0-9 -:\(\)\/]{0,64}$/;
        return typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "grad_date":
        regex = /^$|^((0[1-9]|1[0-2])\/[12]\d{3})$/;
        //regex = /^[0-9]{1,2}[-\/][0-9]{1,4}$/;
        //regex = /^[0-9]{1,4}[-\/][0-9]{1,2}[-\/][0-9]{1,4}$/;
        return !input || typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "year":
        regex = /^$|^$|^[0-9]{4}$/;
        return typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "event_interest":
        regex = /^[A-Za-z 0-9'-:;\(\)\.,\+\&\/]{0,256}$/;
        return typeof input === 'string' && regex.test(input)
      case "clubs":
        regex = /^[A-Za-z 0-9'-:;\(\)\.,\+\&\/]{0,256}$/;
        return !input || typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "zip":
        regex = /^$|^[0-9]{5}$/;
        return !input || typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "wage":
      case "salary":
        regex = /^[0-9\$-\/ A-Za-z\+,]{0,64}$/;
        return typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "needs_update":
        return ["true", "false"].includes(input)// ? input : "true";
      case "name":
        regex = /^[A-Za-z '-]{0,64}$/
        return typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "phone":
        regex = /^$|^\(?([0-9]{3})\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$/;
        return !input || typeof input === 'string' && regex.test(input)//? input?.replace("'", "''") : '';
      case "email":
        //regex = /^[^@\s]{1,30}@[^@\s]{2,25}\.[^@\s]{2,13}$/
        regex = /^$|^[-_0-9a-zA-Z\.\$]{1,30}@[-_0-9a-zA-Z\.]{2,32}\.[-_0-9a-zA-Z\.]{2,13}$/
        return typeof input === 'string' && regex.test(input)// ? input?.replace("'", "''").toLowerCase() : '';
      case "nyjid":
        regex = /^$|^[0-9]{1,8}$/;
        return typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "event_id":
        regex = /^$|^[0-9]{1,10000}$/
        return typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "sub":
        regex = /^$|^[0-9a-f\-]{30,45}$/
        return typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "linkedin":
        regex = /^[A-Za-z 0-9'-_:\(\)\.\+\&\/=\?\%]{0,128}$/
        return !input || typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "updated_at":
        //regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/
        regex = /^$|^[0-9]{1,2}[-\/][0-9]{1,2}[-\/][0-9]{1,4}$/;
        return typeof input === 'string' && regex.test(input) //? input?.replace("'", "''") : '';
      case "job_title":
        regex = /^[a-zA-Z\/0-9 -\.\&,']{0,80}$/;
        return typeof input === 'string' && regex.test(input)
      default:
        return ''
    }
  } catch (err) {
    console.log(err);
    return ''
  }
}


export function shuffleArray(arr) {
  if (!arr) return arr
  for (let i = arr.length - 1; i > 0; i--) {
    let idx = Math.floor(Math.random() * (i + 1));
    //x = arr[i]
    //arr[i] = arr[idx]
    //arr[idx] = x
    [arr[i], arr[idx]] = [arr[idx], arr[i]]
  }
  return arr
}

export async function registerClick(elementID, jobID, setLoggedIn) {//}, sessionID) {
  let sessionID = window.sessionStorage.getItem("sessionID")
  let headers = new Headers();
  headers.append("Content-Type", "application/json")
  headers.append("Accept", "application/json")
  headers.append("Authorization", `Bearer ${window.sessionStorage.getItem("accessToken")}`)

  let payload = {
    sessionID: sessionID,
    elementID: elementID,
    jobID: jobID
  }
  let options = {
    headers: headers,
    body: JSON.stringify(payload),
    redirect: "follow",
    method: "POST"
  }
  return fetch(`${window.sessionStorage.getItem("globalURL")}/click`, options)
    .then(resp => {
      if (resp.status === 401) {
        setLoggedIn(false);
        window.location.reload();
        window.sessionStorage.clear();
        throw new Error("Authentication Error")
      }
      return resp.json()
    })
    .catch((e) => {
      console.log(e)
    })
}