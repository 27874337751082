import React, { useState, useEffect, useContext, useRef } from "react";
import Footer from "../../components/Footer/Footer";
import Dashboard from "../../components/Dashboard";
import Menu from "../../components/Menu"
import "../../index.scss";
import "./_LoggedOn.scss";
import EventContext from "../../components/EventContext";
import IsChatLaunchedContext from "../../components/IsChatLaunchedContext";

const LoggedOn = () => {

  const eventContext = useContext(EventContext);
  const isChatLaunchedContext = useContext(IsChatLaunchedContext);

  function launchAssistant() {
    let userName = sessionStorage.getItem("userName");

    function onLoad(instance) {
      instance.updateCSSVariables({
        "BASE-height": "100vh",
        "BASE-width": `${Math.min(window.screen.width, 500)}px`,
      });
      function loginHandler(event) {
        event.data.context.skills["actions skill"] = event.data.context.skills[
          "actions skill"
        ] || { skills_variables: {} };

        event.data.context.skills["actions skill"].skill_variables.user_name = userName;
      }
      // restart session when page is reloaded
      //window.onunload
      window.onpagehide = function () {
        instance.restartConversation();
        instance.destroySession();
        instance.destroy();
      }

      instance.once({ type: "pre:receive", handler: loginHandler });
      instance.updateIdentityToken(window.sessionStorage.getItem("identityToken"));

      instance.render();
    }

    window.watsonAssistantChatOptions = {
      integrationID: "16bdc5d9-3dca-4afe-a376-39d4d5013acd",
      region: "us-south",
      serviceInstanceID: "f7ad7c64-9e16-47d2-9bcf-b4cff609aea5",
      identityToken: window.sessionStorage.getItem("identityToken"),
      hideCloseButton: false,
      showLauncher: true,
      openChatByDefault: false,
      onLoad: onLoad,
    };
    setTimeout(function () {
      const t = document.createElement("script");
      t.src =
        "https://web-chat.global.assistant.watson.appdomain.cloud/versions/" +
        (window.watsonAssistantChatOptions.clientVersion || "latest") +
        "/WatsonAssistantChatEntry.js";
      document.head.appendChild(t);
    });
  }

  function checkLogin() {
    if (window.location.pathname === "/home" && !isChatLaunchedContext.assistantLaunched) {
      launchAssistant();
      function maxTimeLogout() {
        window.location.reload();
        window.sessionStorage.clear()
      }
      //User is automatically logged out after 3 hours
      let maxSessionTime = setTimeout(() => maxTimeLogout(), 7200000);
      isChatLaunchedContext.setAssistantLaunched(true);
    }
  }

  useEffect(() => {
    checkLogin();
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="page white-page">
      <div id="webchat" className="WebChatContainer"></div>
      <div style={{ display: "flex" }}>
        <Menu />
        <Dashboard />
      </div>
      <Footer />
    </div>
  );
};

export default LoggedOn;
