import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";

import "./_Menu.scss";
import { Work, Home, Event, GroupWork } from "@mui/icons-material";
import Badge from "@mui/material/Badge";

import EventContext from "../EventContext";
//import JobRecommendationsContext from "../JobRecommendationsContext";
import InfoContext from "../InfoContext";
import VisitedPagesContext from "../VisitedPagesContext";


// NOT USED
function Menu() {
  /*const jobRecommendationsContext = useContext(JobRecommendationsContext);*/
  const eventContext = useContext(EventContext);
  const infoContext = useContext(InfoContext);
  const visitedPagesContext = useContext(VisitedPagesContext);
  return (
    <div className="menu">
      <div
        style={{
          minHeight: 199,
          minWidth: 8,
          marginLeft: 12,
          backgroundColor: "rgb(248,248,248)",
          borderRadius: 0,
        }}
      ></div>
      <div>
        <div
          className={`menu-item ${window.location.pathname === "/home" ? "active-menu-link" : ""
            }`}
        >
          <Link
            className={`menu-link ${window.location.pathname === "/home" ? "active-link" : ""
              }`}
            style={{ fontFamily: "Trim-SemiBold" }}
            to="/home"
          >
            <Home
              style={{
                marginRight: 16,
                position: "relative",
                top: -1,
                color:
                  window.location.pathname === "/home"
                    ? "rgb(247,135,15)"
                    : "white",
              }}
            />
            Home
          </Link>
        </div>
        <div
          className={`menu-item ${window.location.pathname === "/events" ? "active-menu-link" : ""
            }`}
        >
          <Link
            onClick={() =>
              visitedPagesContext.setVisited({
                ...visitedPagesContext.visited,
                events: true,
              })
            }
            className={`menu-link ${window.location.pathname === "/events" ? "active-link" : ""
              }`}
            style={{ fontFamily: "Trim-SemiBold" }}
            to="/events"
          >
            <Badge
              badgeContent={
                visitedPagesContext.visited.events
                  ? null
                  : eventContext.events?.filter(
                    (e) =>
                      new Date(e.CREATED || null) >
                      new Date(infoContext.info?.lastLogin || null)
                  )?.length
              }
              color="error"
              sx={{ marginRight: "16px" }}
            >
              <Event
                style={{
                  position: "relative",
                  top: -1,
                  color:
                    window.location.pathname === "/events"
                      ? "rgb(247,135,15)"
                      : "white",
                }}
              />
            </Badge>
            Events
          </Link>
        </div>
        <div
          className={`menu-item ${window.location.pathname === "/resources" ? "active-menu-link" : ""
            }`}
        >
          <Link
            className={`menu-link ${window.location.pathname === "/resources" ? "active-link" : ""
              }`}
            style={{ fontFamily: "Trim-SemiBold" }}
            to="/resources"
          >
            <GroupWork
              style={{
                marginRight: 16,
                position: "relative",
                top: -1,
                color:
                  window.location.pathname === "/resources"
                    ? "rgb(247,135,15)"
                    : "white",
              }}
            />
            Resources
          </Link>
        </div>
        <div
          className={`menu-item ${window.location.pathname === "/jobs" ? "active-menu-link" : ""
            }`}
        >
          <Link
            className={`menu-link ${window.location.pathname === "/jobs" ? "active-link" : ""
              }`}
            style={{ fontFamily: "Trim-SemiBold" }}
            to="/jobs"
          >
            {
              <Work
                style={{
                  position: "relative",
                  top: -1,
                  marginRight: 16,
                  color:
                    window.location.pathname === "/jobs"
                      ? "rgb(247,135,15)"
                      : "white",
                }}
              />
            }
            Jobs
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Menu;
