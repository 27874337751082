import React, { useEffect } from "react";
import ProfileComponent from "../../components/ProfileComponent/ProfileComponent";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";

function Profile(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="page white-page">
            <Menu />
            <ProfileComponent {...props} isCheckIn={false} />
            <Footer />
        </div>
    )
}

export default Profile;