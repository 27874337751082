import React, { useState, useEffect } from "react";
import {
    Modal,
} from "@carbon/react";
import { registerClick } from "../../Helpers";

function JobModal({ open, setOpen, job, setLoggedIn }) {

    return (<Modal
        style={{}}
        modalHeading={job?.COMPANY}
        primaryButtonText={`Apply on company site`}
        hasScrollingContent
        onRequestSubmit={() => {
            window.open(job?.LINK, "_blank");
            setOpen(false);
            registerClick('apply-job-popover', job?.ID, setLoggedIn);
        }}
        onRequestClose={() => setOpen(false)}
        open={open}
    >
        <h4
            style={{
                color: "rgb(91,89,89)",
                marginBottom: "21px",
                fontFamily: "Trim-Bold",
            }}
        >
            {job?.POSITION}
        </h4>
        <div
            style={{
                display: "flex",
                marginBottom: 24,
                marginTop: 8,
            }}
        >
            Application deadline:{" "}
            {new Date(
                job?.APPLICATION_DEADLINE?.slice(0, 10)
            ).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                timeZone: "UTC",
            })}
        </div>
        <div
            dangerouslySetInnerHTML={{
                __html: job?.DESCRIPTION?.replaceAll(
                    "\n",
                    "<br />"
                )?.replaceAll("®", "&#174;"),
            }}
        ></div>
    </Modal>
    )
}


export default JobModal;