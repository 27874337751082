import React from "react";
import "../../index.scss";
import "./_Footer.scss";

const Footer = (props) => {
  return (
    <div className="footer">
      <div className="footer-terms"><a target="_blank" className="footer-terms" href="/Files/DataPrivacyPolicy.pdf"> Data Privacy Policy</a></div>
      <div className="footer-copyright"><span >&copy; 2024 New York Jobs CEO Council</span></div>
      <div className="footer-terms"><a target="_blank" className="footer-terms" href="mailto:talentnetwork@nyjobsceocouncil.org">Contact Us</a></div>
    </div>
  );
};

export default Footer;