import React, {useEffect, useState} from "react";
import "./_ProgressiveImage.scss";

const ProgressiveImage = ({ placeholderSrc, src, style, ...props }) => {
    
    const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          setImgSrc(src);
        };
      }, [src]);

    return (
      <img
        {...{ src: imgSrc, ...props }}
        alt={props.alt || ""}
        style={style}
        className={placeholderSrc && imgSrc === placeholderSrc ? " loading" : " loaded"}
      />
    );
  };
  export default ProgressiveImage;